import { MonthDayValue, MonthDayValueProps } from './month-day-value';

export interface MonthDayProps {
  date: Date;
  values: MonthDayValueProps[];
  mode?: string;
  background?: string;
  selected?: boolean;
  onClick?: () => void;
}

export const MonthDay = (props: MonthDayProps) => {
  const isToday = props.date.dateMatches(new Date());

  switch (props.mode) {
    case 'disabled':
      return (
        <div className="border-silver-200 bg-silver-100 min-h-8 rounded-md border text-sm sm:text-base"></div>
      );
    default:
      return (
        <div
          onClick={props.onClick}
          className="relative flex min-h-8 items-center justify-center rounded-md border text-sm sm:text-base"
          style={{
            color: props.values.at(0)?.color,
            background: props.background
              ? !props.selected
                ? props.background
                : 'var(--color-focus)'
              : '',
            borderColor:
              isToday && !props.selected
                ? '#fb923c'
                : props.selected
                  ? 'var(--color-focus)'
                  : '#dfdfdf'
          }}
        >
          {props.values.map((v, i) => (
            <MonthDayValue key={i} {...v} />
          ))}
        </div>
      );
  }
};
