import { useContext, useEffect, useState } from 'react';
import { HolidayLowerHeader } from '../holiday-common/holiday-lower-header';
import { ExpansionPanel } from '@kidsmanager/ui-core';

import { ClientBackendContext } from '@kidsmanager/ui-api';
import { Booking } from '../holiday-common/holiday-helpers';
import { HolidayBookingItem } from '../holiday-common/holiday-booking-item';
import { ProRataCalculator } from '@kidsmanager/util-common';
import { useNavigate, useParams } from 'react-router-dom';

type YearBookingType = {
  year: string;
  bookings: Booking[];
  allowance: number;
  used: number;
};

export const HolidayViewHistory = () => {
  const client = useContext(ClientBackendContext);
  const params = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [byYear, setByYear] = useState<YearBookingType[]>([]);

  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();

    Promise.all([
      client.holiday.bookings(params.userId),
      client.holiday.allowances(params.userId),
      client.holiday.publicHolidays(year - 1, year, year + 1)
    ]).then(([bookings, allowances, publicHolidays]) => {
      const prorata = new ProRataCalculator(allowances, 'days');
      const byYear = bookings.reduce((acc, booking) => {
        const year = booking.from.getFullYear().toString();
        let yearsBookings = acc.find((x) => x.year === year);
        if (!yearsBookings) {
          yearsBookings = {
            year,
            bookings: [],
            allowance: prorata.forYear(Number(year)),
            used: 0
          };
          acc.push(yearsBookings);
        }
        yearsBookings.bookings.push({
          ...booking,
          days: booking.from.workingDaysUntil(booking.to, publicHolidays)
        });
        yearsBookings.used +=
          yearsBookings.bookings[yearsBookings.bookings.length - 1].days;
        return acc;
      }, [] as YearBookingType[]);

      byYear.sort((a, b) => (b.year < a.year ? -1 : 1));
      byYear.forEach((year) => {
        year.bookings.sort((a, b) => (a.from < b.from ? 1 : -1));
      });
      setByYear(byYear);
    });
  }, [client, params.userId]);

  const handleUserSelected = (userId: string) => {
    navigate(`/holiday/${userId}/history`);
  };

  return (
    <div>
      <HolidayLowerHeader onUserSelected={handleUserSelected.bind(this)} />
      {byYear.length === 0 && (
        <div className="p-4">Keine Urlaubsbuchungen gefunden...</div>
      )}
      {byYear.length > 0 && (
        <div className="-m-2 flex flex-col gap-2 bg-neutral-200 px-2 py-4">
          {byYear.map(({ year, bookings, allowance, used }, index) => (
            <ExpansionPanel
              id={year}
              label={year}
              key={`group-${index}`}
              expanded={index === 0}
              meta={`${used} von ${allowance} benutzt`}
            >
              {bookings.map((booking, index) => (
                <div
                  key={index}
                  className={`border-silver-200 relative ml-4 pb-4 pt-2 ${index !== bookings.length - 1 ? 'border-b' : ''}`}
                >
                  <HolidayBookingItem
                    userId={params.userId}
                    from={booking.from}
                    to={booking.to}
                    approvedBy={booking.approvedBy}
                    approvedWhen={booking.approvedWhen}
                    days={booking.days}
                  />
                </div>
              ))}
            </ExpansionPanel>
          ))}
        </div>
      )}
    </div>
  );
};
