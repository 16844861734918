export interface InfoProps {
  children: React.ReactNode;
  position?: 'left' | 'right' | 'center';
}

export const Info = (props: InfoProps) => {
  const getPosition = () => {
    switch (props.position) {
      case 'right':
        return 'right-0';
      case 'center':
        return 'left-1/2 -translate-x-1/2';
      default:
        return 'left-0';
    }
  };

  return (
    <details className="relative leading-none">
      <summary className="outline-focus outline-offset- cursor-default list-none">
        <span
          className="material-icons cursor-pointer text-black/50"
          style={{ fontSize: '20px', userSelect: 'none' }}
        >
          info
        </span>
      </summary>
      <div
        className={`border-silver-100 absolute w-80 rounded border bg-white px-6 py-4 text-left shadow-xl ${getPosition()} z-50`}
      >
        {props.children}
      </div>
    </details>
  );
};
