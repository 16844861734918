import { useContext, useEffect, useMemo, useState } from 'react';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import {
  Select,
  Option,
  DateRangePicker,
  LoadSpinner,
  Button,
  Input,
  Revealer
} from '@kidsmanager/ui-core';
import { useNavigate, useParams } from 'react-router-dom';
import { absenceExpand, AbsenceOption, parseOutMins } from './absence-expander';

const DefaultStartMins = 9 * 60;
const DefaultStartTime = '09:00';

export const AbsenceNew = () => {
  const [options, setOptions] = useState<AbsenceOption[]>([]);
  const [publicHolidays, setPublicHolidays] = useState<Date[]>([]);
  const [hoursPerDay, setHoursPerDay] = useState<number>(0);
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [startTime, setStartTime] = useState<string>(DefaultStartTime);
  const [endTime, setEndTime] = useState<string>('');
  const [workDays, setWorkDays] = useState<number>(0);
  const [selected, setSelected] = useState<AbsenceOption>();
  const [saving, setSaving] = useState<boolean>(false);

  const client = useContext(ClientBackendContext);
  const params = useParams<{ date: string }>();
  const navigate = useNavigate();

  const date = useMemo(
    () => (params.date ? new Date(params.date) : new Date()),
    [params.date]
  );

  useEffect(() => {
    Promise.all([
      client.timesheet.shifts(),
      client.holiday.publicHolidays(
        date.getFullYear() - 1,
        date.getFullYear(),
        date.getFullYear() + 1
      ),
      client.timesheet.contracted(date)
    ]).then(([shifts, publicHols, contracted]) => {
      const values: AbsenceOption[] = [];
      for (const shift of shifts) {
        for (const absenceType of shift.nonWork) {
          if (
            values.find(
              (v) => v.label.toLowerCase() === absenceType.name.toLowerCase()
            )
          ) {
            continue;
          }
          values.push({
            label: absenceType.name,
            tag: absenceType.abbr,
            code: absenceType.code,
            infoOnly: absenceType.infoOnly
          });
        }
      }
      setOptions(values);
      setSelected(values.at(0));
      setPublicHolidays(publicHols);
      setHoursPerDay(contracted.hoursPerDay);

      const endTotalMins = DefaultStartMins + contracted.hoursPerDay * 60;
      setStartTime(DefaultStartTime);
      setEndTime(
        `${Math.floor(endTotalMins / 60)
          .toString()
          .padStart(2, '0')}:${(endTotalMins % 60).toString().padStart(2, '0')}`
      );
    });
  }, [client, date]);

  const handleDateChange = (start?: Date, end?: Date) => {
    setFrom(start);
    setTo(end || start);
    if (start) {
      setWorkDays(start.workingDaysUntil(end || start, publicHolidays));
    } else {
      setWorkDays(0);
    }
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const match = options.find((o) => o.label === e.target.value);
    if (match) {
      setSelected(match);
    }
  };

  const handleSave = async () => {
    if (!from || !to || !selected) {
      return;
    }
    setSaving(true);
    await Promise.all(
      absenceExpand(
        from,
        to,
        startTime,
        endTime,
        hoursPerDay,
        publicHolidays,
        selected
      ).map((item) => client.timesheet.log.create(item.from, item.entry))
    );
    setSaving(false);
    navigate(`/work/my/${from.toLocaleISODate()}/week`);
  };

  const description = useMemo(() => {
    if (!from || !to) {
      return `- Werktage, ${selected?.label}`;
    }
    const diffHrs = (parseOutMins(endTime) - parseOutMins(startTime)) / 60;

    if (workDays < 2 && diffHrs < hoursPerDay) {
      if (diffHrs > 0.9 && diffHrs < 1.1) {
        return `1 Stunde, ${selected?.label}`;
      } else {
        return `${diffHrs} Stunden, ${selected?.label}`;
      }
    } else {
      return `${workDays} Werktage, ${selected?.label}`;
    }
  }, [from, to, selected, workDays, startTime, endTime, hoursPerDay]);

  return (
    <div className="px-4 py-5">
      <Select
        label="Kategorie"
        defaultValue={selected?.label}
        onChange={handleCategoryChange.bind(this)}
      >
        {options.map((option, i) => (
          <Option key={i} value={option.label}>
            {option.label}
          </Option>
        ))}
      </Select>
      <div className="pt-4">
        <DateRangePicker
          stacked
          start={date}
          publicHolidays={publicHolidays}
          onChange={handleDateChange.bind(this)}
        />
      </div>
      <div className="mt-8 text-center">
        <Revealer show={workDays < 2}>
          <form className="mx-auto my-4 flex max-w-xs items-center gap-4">
            <Input
              value={startTime}
              onChange={setStartTime.bind(this)}
              mask="time"
              disabled={workDays === 0}
            />
            <span>bis</span>
            <Input
              value={endTime}
              mask="time"
              disabled={workDays === 0}
              onChange={setEndTime.bind(this)}
            />
          </form>
        </Revealer>
        <p className="mb-8 mt-4 text-lg">{description}</p>

        <LoadSpinner show={saving}>
          <Button
            color="primary"
            disabled={saving || !from || !to}
            onClick={handleSave.bind(this)}
          >
            <span className="inline-block p-2 uppercase tracking-widest">
              Spiechern
            </span>
          </Button>
        </LoadSpinner>
      </div>
    </div>
  );
};
