import { useNavigate, useParams } from 'react-router-dom';
import {
  DayValues,
  MonthCalendarView
} from './compoenents/month-calendar-view';
import { MonthSummary } from './compoenents/month-summary';
import { MonthDayDetails } from './compoenents/month-day-details';
import { TimesheetLowerHeader } from '../timesheet-common/timesheet-lower-header';
import { TimesheetStats } from '../timesheet-common/timesheet-stats';
import { useContext, useEffect, useState } from 'react';
import { DropdownMenu } from '@kidsmanager/ui-core';
import { ClientBackendContext } from '@kidsmanager/ui-api';
import { IHoursForMonth } from 'libs/ui-api/src/lib/timesheet/api-timesheet-helper';
import { compileView, Filter, workingDays } from './helpers/view-compiler';

export const TimesheetViewMonth = () => {
  const [actual, setActual] = useState(0);
  const [contracted, setContracted] = useState<IHoursForMonth>();
  const [selected, setSelected] = useState<Date>();
  const [date, setDate] = useState<Date>(new Date());
  const [days, setDays] = useState<DayValues[]>([]);
  const [workDays, setWorkDays] = useState(0);
  const [filter, setFilter] = useState<Filter>(
    (localStorage.getItem('timesheet-filter') as Filter) || 'both'
  );

  const params = useParams<{ userId: string; date: string }>();
  const client = useContext(ClientBackendContext);
  const navigate = useNavigate();

  useEffect(() => {
    const date = new Date(params.date || Date.now());
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    setDate(date);
    Promise.all([
      client.timesheet.month(year, month, params.userId),
      client.timesheet.contracted(date, params.userId),
      client.holiday.publicHolidays(year),
      client.holiday.bookingsForYear(year, params.userId),
      client.roster.shifts.for(year, month, params.userId),
      client.roster.plan.released(year, month, params.userId)
    ]).then(([logged, contracted, publicHols, holidays, specs, plan]) => {
      setContracted(contracted);
      setActual(logged.daysTotal);
      setDays(
        compileView(date, holidays, publicHols, specs, plan, logged, filter)
      );
      setWorkDays(workingDays(date, publicHols));
    });
  }, [params.date, params.userId, client, filter]);

  const onClicked = (date?: Date) => {
    setSelected((prev) => {
      if (!date) return undefined;
      return prev?.getTime() === date.getTime() ? undefined : date;
    });
  };

  const onFilterChanged = (value: string) => {
    setFilter(value as Filter);
    localStorage.setItem('timesheet-filter', value);
  };

  const onMove = (delta: number) => {
    const d = new Date(
      date.getFullYear(),
      date.getMonth() + delta,
      date.getDate()
    );
    navigate(`/work/${params.userId}/${d.toLocaleISODate()}/month`);
  };

  const handleUserSelected = (userId: string) => {
    navigate(`/work/${userId}/${params.date}/month`);
  };

  return (
    <>
      <TimesheetLowerHeader onUserSelected={handleUserSelected.bind(this)}>
        <div className="flex items-center px-4 text-center text-2xl sm:block sm:text-3xl">
          <span
            className="material-icons cursor-pointer hover:text-black/50"
            style={{ userSelect: 'none' }}
            onClick={onMove.bind(this, -1)}
          >
            arrow_back_ios
          </span>
          <span className="inline-block min-w-36 md:mx-2">
            {date
              .toLocaleString('de-DE', { month: 'short', year: 'numeric' })
              .replace('.', '')}
          </span>
          <span
            className="material-icons cursor-pointer hover:text-black/50"
            style={{ userSelect: 'none' }}
            onClick={onMove.bind(this, 1)}
          >
            arrow_forward_ios
          </span>
        </div>
        <TimesheetStats
          actual={actual}
          planned={workDays * (contracted?.hoursPerDay || 0)}
        />
      </TimesheetLowerHeader>
      <div className="p-2">
        <div className="pb-2 text-right text-xs">
          <DropdownMenu
            selected={filter}
            options={[
              { key: 'logged', label: 'Eingetragene' },
              { key: 'planned', label: 'Geplante' },
              { key: 'both', label: 'Geplante & Eingetragene' }
            ]}
            onChange={onFilterChanged.bind(this)}
          />
        </div>
        <MonthCalendarView
          date={date}
          days={days}
          onClick={onClicked.bind(this)}
          selected={selected}
          userId={params.userId}
        />
      </div>
      <div className="flex justify-center py-10">
        <div className="max-w-lg flex-1">
          {selected && (
            <MonthDayDetails userId={params.userId} date={selected} />
          )}
          {!selected && (
            <MonthSummary
              date={date}
              workDays={workDays}
              hoursPerWeek={contracted?.hoursPerDay || 0}
              actual={actual}
              planned={workDays * (contracted?.hoursPerDay || 0)}
            />
          )}
        </div>
      </div>
    </>
  );
};
