export interface MonthSummaryProps {
  date: Date;
  actual: number;
  planned: number;
  hoursPerWeek: number;
  workDays: number;
}

export const MonthSummary = ({
  date,
  actual,
  planned,
  hoursPerWeek,
  workDays
}: MonthSummaryProps) => {
  return (
    <table className="w-full">
      <tbody>
        <tr>
          <td>Vorheriger Monat</td>
          <td className="text-right">{(0).toFixed(2)}</td>
        </tr>
        <tr>
          <td>
            Geplant [{hoursPerWeek.toFixed(1)} pro Woche, {workDays} Tage]
          </td>
          <td className="text-right">{planned.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Gearbeitet</td>
          <td className="text-right">{actual.toFixed(2)}</td>
        </tr>
      </tbody>
    </table>
  );
};
