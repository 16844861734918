import { IUser, IUserAllowance } from '@kidsmanager/util-models';
import { IAuth, fetch } from '../auth';
import { BackendCache } from '../backend-cache';

const cache = new BackendCache();

export class ApiAdminUsers {
  constructor(
    private auth: IAuth,
    private fetch: fetch
  ) {}

  async active(): Promise<IUser[]> {
    return cache.get('admin_user', async () => {
      const result = await this.fetch('api/admin/user');
      if (result.ok) {
        return result.json();
      }
      return [];
    });
  }

  async locked(): Promise<IUser[]> {
    return cache.get('admin_locked_user', async () => {
      const result = await this.fetch('api/admin/user?locked=true');
      if (result.ok) {
        return result.json();
      }
      return [];
    });
  }

  async google(): Promise<string[]> {
    return cache.get('admin_google_user', async () => {
      const response = await this.fetch('/api/admin/google/user');
      if (response.ok) {
        return response.json();
      }
      return [];
    });
  }
  async update(user: IUser): Promise<IUser> {
    const body = JSON.stringify(user); // only sends the modified fields
    const response = await this.fetch(`api/admin/user/${user.userId}`, {
      method: 'PUT',
      body
    });
    cache.clear('admin_user');
    return response.json();
  }

  async create(user: IUser): Promise<IUser | null> {
    const body = JSON.stringify(user);
    const response = await this.fetch(`api/admin/user`, {
      method: 'POST',
      body
    });
    if (response.ok) {
      cache.clear('admin_user');
      return response.json();
    } else {
      return null;
    }
  }

  async allowances(
    userId: string
  ): Promise<{ [type: string]: IUserAllowance[] }> {
    return cache.get(`allowances_${userId}`, async () => {
      const response = await this.fetch(`api/admin/user/${userId}/allowances`);
      return response.json();
    });
  }

  async updateAllowances(
    userId: string,
    type: 'hours' | 'holiday',
    allowances: IUserAllowance[]
  ): Promise<IUserAllowance[] | null> {
    const body = JSON.stringify(allowances);
    const response = await this.fetch(
      `api/admin/user/${userId}/allowances?type=${type}`,
      {
        method: 'PUT',
        body
      }
    );
    if (response.ok) {
      cache.clear(`allowances_${userId}`);
      return response.json();
    } else {
      return null;
    }
  }
}
