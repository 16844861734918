export {}; // Ensure this is treated as a module to allow us to define global augmentation

declare global {
  interface Date {
    /**
     * Get the week number of the year based on the FirstFourDayWeek rule.
     * @remarks uses a trick that January 4 is always in week 1.
     */
    getWeek: () => number;

    /**
     * Get the first day of the week for the given date.
     */
    firstDayOfWeek: () => Date;

    /**
     * Set the date to the first day of the week based on the week number.
     * @param week The week number to set the date to.
     * @returns the date set to the first day of the week.
     */
    setWeek: (week: number) => Date;

    /**
     * Set ths time of the day in seconds
     * @param seconds
     * @returns immutable date with the time set to the provided seconds
     */
    setTotalSeconds: (seconds: number) => Date;

    /**
     * Check if the date part of the date matches the date part of the provided date.
     * @param date The date to compare to.
     * @returns true if the date parts match, false otherwise.
     */
    dateMatches: (date?: Date) => boolean;

    /**
     * Convert the date to an ISO formatted string e.g. 2024-01-23.
     * @returns the date as an ISO formatted string.
     */
    toLocaleISODate: () => string;

    /**
     * Convert the date to an ISO formatted string e.g. 2024-01.
     * @returns the date as an ISO formatted string.
     */
    toLocaleISOMonth: () => string;

    /**
     * Provides a short user-friendly string representation of the date.
     * @param locales The locale to use for formatting.
     * @returns 'Heute' if the date is today, otherwise a short date string.
     */
    toUserFriendlyString: (locales: string) => string;

    /**
     * Calculate the number of working days until the provided date.
     * @param until The date to calculate the working days until.
     * @param ignore Dates to ignore when calculating working days.
     * @returns the number of working days until the provided date.
     */
    workingDaysUntil: (until: Date, ignore: Date[]) => number;

    /**
     * Calculate the first day of the next month.
     * @returns the first day of the next month.
     */
    nextMonth: () => Date;

    /**
     * Calculate the first day of the previous month.
     * @returns the first day of the previous month.
     */
    previousMonth: () => Date;

    /**
     * Time part of Date as number of seconds from UTC midnight
     * @returns seconds since UTC midnight
     */
    toApiSeconds: () => number;

    /**
     * Lexically sortable ticks, which when used as sort key
     * will return most recent date first
     * @param value date to convert
     * @returns string representation of ticks
     */
    toSortableTicks: () => string;

    isWeekend: () => boolean;

    /**
     * Returns true if date, regardless of time, is after the provided date.
     * @param date
     * @returns
     */
    isAfter: (date: Date) => boolean;

    /**
     * Add days to the date
     * @param days
     * @returns
     */
    addDays: (days: number) => Date;
  }
}

export const ticksPerDay = 86400000;

Date.prototype.getWeek = function () {
  // Thursday in current week decides the year.
  const thurs = new Date(
    this.getFullYear(),
    this.getMonth(),
    this.getDate() + 3 - ((this.getDay() + 6) % 7)
  );
  // January 4 is always in week 1.
  const jan4 = new Date(thurs.getFullYear(), 0, 4);
  const count = (thurs.getTime() - jan4.getTime()) / ticksPerDay;

  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return 1 + Math.round((count - 3 + ((jan4.getDay() + 6) % 7)) / 7);
};

Date.prototype.firstDayOfWeek = function () {
  const offsetDays = this.getDay() === 0 ? 6 : this.getDay() - 1;
  const dateTicks = this.getTime() - offsetDays * 24 * 60 * 60 * 1000;
  const x = new Date(dateTicks);
  return new Date(x.getFullYear(), x.getMonth(), x.getDate());
};

Date.prototype.setWeek = function (week: number) {
  const firstMonday = new Date(this.getFullYear(), 0, 1);
  while (firstMonday.getDay() !== 1) {
    // 1 represents Monday
    firstMonday.setDate(firstMonday.getDate() + 1);
  }
  const firstWeek = firstMonday.getWeek();
  const weekDiff = week - firstWeek;
  return new Date(firstMonday.setDate(firstMonday.getDate() + weekDiff * 7));
};

Date.prototype.dateMatches = function (date?: Date) {
  if (!date) return false;
  return (
    this.getFullYear() === date.getFullYear() &&
    this.getMonth() === date.getMonth() &&
    this.getDate() === date.getDate()
  );
};

Date.prototype.toLocaleISODate = function () {
  const year = this.getFullYear();
  const month = String(this.getMonth() + 1).padStart(2, '0');
  const day = String(this.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

Date.prototype.toLocaleISOMonth = function () {
  const year = this.getFullYear();
  const month = String(this.getMonth() + 1).padStart(2, '0');
  return `${year}-${month}`;
};

Date.prototype.toUserFriendlyString = function (locales: string) {
  const today = new Date();
  return today.dateMatches(this)
    ? 'Heute'
    : this.toLocaleString(locales, {
        day: '2-digit',
        month: 'short'
      }).replace(/\./g, '');
};

Date.prototype.workingDaysUntil = function (until: Date, ignore: Date[]) {
  let duration = 0;
  let day = this.getTime();
  const end = until.getTime();
  while (day <= end) {
    const d = new Date(day);
    const dayOfWeek = d.getDay();
    const isPublicHoliday =
      ignore.findIndex(
        (x) =>
          x.getDate() === d.getDate() &&
          x.getFullYear() === d.getFullYear() &&
          x.getMonth() === d.getMonth()
      ) >= 0;
    duration += isPublicHoliday || dayOfWeek === 0 || dayOfWeek === 6 ? 0 : 1;
    day += 24 * 60 * 60 * 1000;
  }
  return duration;
};

Date.prototype.nextMonth = function () {
  return new Date(this.getFullYear(), this.getMonth() + 1, 1);
};

Date.prototype.previousMonth = function () {
  return new Date(this.getFullYear(), this.getMonth() - 1, 1);
};

Date.prototype.setTotalSeconds = function (seconds: number) {
  // applied in an immutable way
  return new Date(new Date(this).setHours(0, 0, 0, 0) + seconds * 1000);
};

Date.prototype.toApiSeconds = function () {
  const timezoneAdjustment = this.getTimezoneOffset() * 60;
  return (
    this.getHours() * 60 * 60 + this.getMinutes() * 60 + timezoneAdjustment
  );
};

Date.prototype.toSortableTicks = function () {
  //epoch for C# BigInt('621355968000000000')
  //max for C# BigInt('3155378975999999999')
  //so js_max = BigInt(max) - BigInt(epoch)
  const js_max = BigInt('2534023007999999999');
  const ticks = BigInt(this.getTime() * 10000);
  return String(js_max - ticks).padStart(19, '0');
};

Date.prototype.isWeekend = function () {
  return this.getDay() === 0 || this.getDay() === 6;
};

Date.prototype.isAfter = function (date: Date) {
  if (this.getFullYear() > date.getFullYear()) return true;
  if (this.getMonth() > date.getMonth()) return true;
  if (this.getDate() > date.getDate()) return true;
  return false;
};

Date.prototype.addDays = function (days: number) {
  const date = new Date(this);
  date.setDate(date.getDate() + days);
  return date;
};
